import "@hotwired/turbo-rails"
import "./controllers"

document.addEventListener("turbo:load", (_) => {
  console.log("turbo load was triggered");

  let googleId = document.querySelector("meta[name='google-analytics-id']")?.content;

  if (!googleId) {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    gtag('js', new Date());

    gtag('config', googleId);
  }
})